// https://github.com/mebjas/html5-qrcode

var Config = {
  app: {
		live: true,
		onDevice: false,
		hcpEnabled: false,
		forceSync: true,

		// Set this to paynplan, wodapp, recurme  or ptapp
		currentApp: 'paynplan',

		// Release number is used for caching
		release: 10,

		// Version, used in WS
		version: '14.0',

		// Default language
		languageDefault: 'nl_NL',

		// Platform, ios or android
		platform: 'android',

		// Theme (md, ios, aurora, detect = self auto detect based on Browser or OS)
		theme: 'md',

		// Request method (post or get)
		servercallType: 'post',

		// WS Development
		//baseURL: 'http://dev-personaltrainerapp.nl/ws/',
		//baseURL: 'http://dev-recurme.eu/ws/',

		// WS Staging Development
		//baseURL: 'https://ws-staging-dev.personaltrainerapp.nl',

    // WS Staging
		//baseURL: 'https://ws-staging.personaltrainerapp.nl',

		//WS Production
		baseURL: 'https://ws.paynplan.nl',
		//baseURL: 'https://ws.recurme.eu',
	},

  // Apps config!
  apps: {
    paynplan: {
  		name: 'paynplan',
  		domain: 'paynplan.nl',
  		displayName: 'Pay n Plan',
  		siteURL: 'https://www.paynplan.nl',
  		appversionURL: 'https://app.paynplan.nl',
  		siteEmail: 'info@paynplan.nl',
			supportEmail: 'info@paynplan.nl',
			logoCompany: false,
			logoNavbar: true,
			searchCompanies: true,

  		push: {
  			gmc: '402012131001',
  			pushwoosh: '2C453-04732'
  		},

  		social: {
  			facebook: 'https://www.facebook.com/pages/Pay-n-Plan/710226685723604',
  			twitter: 'https://twitter.com/PaynPlan'
  		}
  	},

  	ptapp: {
  		name: 'ptapp',
  		domain: 'personaltrainerapp.nl',
  		displayName: 'Personal Trainer App',
  		siteURL: 'https://www.personaltrainerapp.nl',
  		appversionURL: 'https://app.personaltrainerapp.nl',
  		siteEmail: 'info@personaltrainerapp.nl',
			supportEmail: 'info@personaltrainerapp.nl',
			logoCompany: true,
			logoNavbar: false,
			searchCompanies: true,

  		push: {
  			gmc: '402012131001',
  			pushwoosh: 'D403D-5F836'
  		},

  		social: {
  			facebook: 'https://www.facebook.com/ptappNL',
  			twitter: 'https://twitter.com/PTApp_NL'
  		}
  	},

  	wodapp: {
  		name: 'wodapp',
  		domain: 'wodapp.nl',
  		displayName: 'WOD App',
  		siteURL: 'https://www.wodapp.nl',
  		appversionURL: 'https://app.wodapp.nl',
  		siteEmail: 'info@wodapp.nl',
			supportEmail: 'info@wodapp.nl',
			logoCompany: false,
			logoNavbar: true,
			searchCompanies: true,

  		push: {
  			gmc: '402012131001',
  			pushwoosh: 'F6B5B-16D1C'
  		},

  		social: {
  			facebook: 'https://www.facebook.com/WODAppNL',
  			twitter: 'https://twitter.com/WODApp_NL'
  		}
  	},

		recurme: {
  		name: 'recurme',
  		domain: 'recurme.eu',
  		displayName: 'Recurme',
  		siteURL: 'https://www.recurme.nl',
  		appversionURL: 'https://app.recurme.eu',
  		siteEmail: 'info@recurme.com',
  		supportEmail: 'info@recurme.eu',
			logoCompany: false,
			logoNavbar: true,
			searchCompanies: false,

  		push: {
  			gmc: '',
  			pushwoosh: ''
  		},

  		social: {
  			facebook: '',
  			twitter: ''
  		}
  	}
  },

  // URL args function to prevent caching!
  cache: {
		urlArgs: function(prefix) {
			if(!prefix) {
				prefix = '?';
			}

			if(Config.app.live) {
				return prefix + 'nc=' + Config.app.release;
			}

			return '?nc=' + (new Date()).getTime();
		}
	}
};

export default Config;
